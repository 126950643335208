<template>
  <v-container>
    <div
      class="pa-5 mt-6 white rounded-lg d-flex align-center justify-space-between"
    >
      <h1 class="text-h5 font-weight-bold">Registros por antena</h1>
      <div>
        <v-btn
          class="font-weight-medium px-8 mr-3"
          @click="$emit('changeComponent', 'ListAntenna', idAntenna)"
          depressed
          color="primary"
          outlined
          >REGRESAR</v-btn
        >
        <v-btn
          class="font-weight-medium px-8"
          @click="$emit('changeComponent', 'ServiceAntenna')"
          depressed
          color="primary"
          >AGREGAR</v-btn
        >
      </div>
    </div>

    <div class="mt-5">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn @click="viewDetail(item)" icon>
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn @click="edit(item)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
// Domains
import Service from "../../domain/Service";

export default {
  name: "DetailsAntenna",
  mounted() {
    this.getServices();
  },
  props: {
    idAntenna: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "client.name",
      },
      { text: "IP", value: "ip" },
      { text: "Usuario", value: "user" },
      { text: "Password", value: "password" },
      { text: "Frecuencia", value: "frequency" },
      { text: "Número celular", value: "client.movilNumber" },
      { text: "Acciones", value: "actions" },
    ],
    items: [],
  }),
  methods: {
    async getServices() {
      try {
        this.items = await Service.findByAntennaID(this.idAntenna);
      } catch (error) {
        console.warn(error);
      }finally{
        this.isLoading = false
      }
    },
    viewDetail(service) {
      this.$emit('service', 'view', service, this.idAntenna);
    },
    edit(service) {
      this.$emit('service', 'edit', service, this.idAntenna);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>